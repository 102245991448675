import image1 from './BathroomRenovation1.jpg' ;
import image2 from './BathroomRenovation2.jpg';
import image3 from './BathroomRenovation3.jpg';
import image4 from './BathroomRenovation4.jpg';
import image5 from './BathroomRenovation5.jpg';
import image6 from './BathroomRenovation6.jpg';
import image7 from './BathroomRenovation7.jpg';
import image8 from './BathroomRenovation8.jpg';
import image9 from './BathroomRenovation9.jpg';
import image10 from './BathroomRenovation10.jpg';
import image11 from './BathroomRenovation11.jpg';




export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image11
    },   
    
]