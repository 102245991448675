import image1 from './ToiletFlat1.jpg' ;
import image2 from './ToiletFlat2.jpg';
import image3 from './ToiletFlat3.jpg';
import image4 from './ToiletFlat4.jpg';
import image5 from './ToiletFlat5.jpg';
import image6 from './ToiletFlat6.jpg';
import image7 from './ToiletFlat7.jpg';
import image8 from './ToiletFlat8.jpg';
import image9 from './ToiletFlat9.jpg';
import image10 from './ToiletFlat10.jpg';
import image12 from './ToiletFlat2.jpg';
import image13 from './ToiletFlat13.jpg';
import image14 from './ToiletFlat14.jpg';




export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image12
    },
    {
        img: image13
    },
    {
        img: image14
    },


    
]