import image1 from './KitchenRenovation1.jpg' ;
import image2 from './KitchenRenovation2.jpg';
import image3 from './KitchenRenovation3.jpg';
import image4 from './KitchenRenovation4.jpg';
import image5 from './KitchenRenovation5.jpg';
import image6 from './KitchenRenovation6.jpg';
import image7 from './KitchenRenovation7.jpg';
import image8 from './KitchenRenovation8.jpg';
import image9 from './KitchenRenovation9.jpg';
import image10 from './KitchenRenovation10.jpg';
import image12 from './KitchenRenovation2.jpg';
import image13 from './KitchenRenovation13.jpg';
import image14 from './KitchenRenovation14.jpg';
import image15 from './KitchenRenovation15.jpg';
import image16 from './KitchenRenovation16.jpg';


export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image12
    },
    {
        img: image13
    },
    {
        img: image14
    },
    {
        img: image15
    },
    {
        img: image16
    },
    
]