import image1 from './SingleFamilyHouse1.jpg' ;
import image2 from './SingleFamilyHouse2.jpg';
import image3 from './SingleFamilyHouse3.jpg';
import image4 from './SingleFamilyHouse4.jpg';
import image5 from './SingleFamilyHouse5.jpg';
import image6 from './SingleFamilyHouse6.jpg';
import image7 from './SingleFamilyHouse7.jpg';
import image8 from './SingleFamilyHouse8.jpg';
import image9 from './SingleFamilyHouse9.jpg';
import image10 from './SingleFamilyHouse10.jpg';
import image11 from './SingleFamilyHouse11.jpg';
import image12 from './SingleFamilyHouse12.jpg';
import image13 from './SingleFamilyHouse13.jpg';
import image14 from './SingleFamilyHouse14.jpg';
import image15 from './SingleFamilyHouse15.jpg';
import image16 from './SingleFamilyHouse16.jpg';
import image17 from './SingleFamilyHouse17.jpg';
import image18 from './SingleFamilyHouse18.jpg';




export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image11
    },
    {
        img: image12
    },
    {
        img: image13
    },
    {
        img: image14
    },
    {
        img: image15
    },  {
        img: image16
    },
    {
        img: image17
    },
    {
        img: image18
    },




    
]