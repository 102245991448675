import image1 from './CeilingEnlargement1.jpg' ;
import image2 from './CeilingEnlargement2.jpg';
import image3 from './CeilingEnlargement3.jpg';


export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    
    
    
]