import image1 from './PressureWall1.jpg' ;
import image2 from './PressureWall2.jpg';
import image3 from './PressureWall3.jpg';
import image4 from './PressureWall4.jpg';




export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },

    
]