import image1 from './SingleFamilyHouse1.jpg' ;
import image2 from './SingleFamilyHouse2.jpg';
import image3 from './SingleFamilyHouse3.jpg';
import image4 from './SingleFamilyHouse4.jpg';
import image5 from './SingleFamilyHouse5.jpg';
import image6 from './SingleFamilyHouse6.jpg';
import image7 from './SingleFamilyHouse7.jpg';
import image8 from './SingleFamilyHouse8.jpg';
import image9 from './SingleFamilyHouse9.jpg';
import image10 from './SingleFamilyHouse10.jpg';
import image12 from './SingleFamilyHouse2.jpg';
import image13 from './SingleFamilyHouse13.jpg';
import image14 from './SingleFamilyHouse14.jpg';
import image15 from './SingleFamilyHouse15.jpg';
import image18 from './SingleFamilyHouse18.jpg';
import image19 from './SingleFamilyHouse19.jpg';
import image20 from './SingleFamilyHouse20.jpg';
import image21 from './SingleFamilyHouse21.jpg';
import image22 from './SingleFamilyHouse22.jpg';
import image23 from './SingleFamilyHouse23.jpg';
import image24 from './HomeFinishing1.jpg' ;
import image25 from './HomeFinishing2.jpg';
import image26 from './HomeFinishing3.jpg';
import image27 from './HomeFinishing4.jpg';
import image28 from './HomeFinishing5.jpg';
import image29 from './HomeFinishing6.jpg';

export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image12
    },
    {
        img: image13
    },
    {
        img: image14
    },
    {
        img: image15
    },
    {
        img: image18
    },
    {
        img: image19
    },
    {
        img: image20
    },
    {
        img: image21
    },
    {
        img: image22
    },
    {
        img: image23
    },
    {
        img: image24
    },
    {
        img: image25
    },
    {
        img: image26
    },
    
    {
        img: image27
    },
    {
        img: image28
    },    
    {
        img: image29
    },
    
    
    
    
    
    
    
    
    ]