import image1 from './SingleFamilyHouse1.jpg' ;
import image2 from './SingleFamilyHouse2.jpg';
import image3 from './SingleFamilyHouse3.jpg';
import image4 from './SingleFamilyHouse4.jpg';
import image5 from './SingleFamilyHouse5.jpg';
import image6 from './SingleFamilyHouse6.jpg';




export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },

    
]