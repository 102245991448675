import image1 from './Toilet1.jpg' ;
import image2 from './Toilet2.jpg';
import image3 from './Toilet3.jpg';
import image4 from './Toilet4.jpg';
import image5 from './Toilet5.jpg';
import image6 from './Toilet6.jpg';
import image7 from './Toilet7.jpg';
import image8 from './Toilet8.jpg';
import image9 from './Toilet9.jpg';
import image10 from './Toilet10.jpg';
import image12 from './Toilet2.jpg';
import image13 from './Toilet13.jpg';
import image14 from './Toilet14.jpg';
import image15 from './Toilet15.jpg';



export default  [
    
    {
        img: image1
    },
    {
        img: image2
    },
    {
        img: image3
    },
    {
        img: image4
    },
    {
        img: image5
    },
    {
        img: image6
    },
    {
        img: image7
    },
    {
        img: image8
    },
    {
        img: image9
    },
    {
        img: image10
    },
    {
        img: image12
    },
    {
        img: image13
    },
    {
        img: image14
    },
    {
        img: image15
    },

    
]